export function MarketingLayout(props: {
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <div className='w-full h-full flex'>
      <div
        className={`
          h-full w-full
          bg-layer-001 bg-user-activation
          bg-no-repeat bg-cover bg-bottom
          overflow-hidden
          relative
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
